#advanced-options {
  position: fixed;
  top: 0;
  right: -800px;
  width:100%;
  max-width: 800px;
  height: 100%;
  background-color: white;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  z-index: 20;
  overflow-y: scroll;
}

#advanced-options.open {
  right: 0;
}

#overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 3s ease-in-out;
  display: none;
  z-index: 10;
}

#overlay.open {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 3s ease-in-out;
}
