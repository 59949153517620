.card {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 7px 40px rgb(34 35 58 / 50%);
  /*max-width: 400px;*/
  display: flex;
  flex-direction: row;
  border-radius: 25px;
  position: relative;
}

.avatar-wrap {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
}

.img-avatar {
  width: 6.5em;
  height: 6.5em;
  border-radius: 50%;
  border: 6px solid white;
  background-image: linear-gradient(-60deg, #ddd 0%, #aaa 100%);
  position: absolute;
  overflow: hidden;
  top: 1em;
  left: calc(33% - calc(6.5em * 0.5));
  /*
    */
}

.card-text {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 2fr;
}

.portada {
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-position: bottom center;
  background-size: cover;
}

.title-total {
  padding: 2.5em 1.5em 1.5em 1.5em;
}

.card .title {
  padding: 1rem;
  text-align: right;
  font-weight: bold;
  font-size: 12px;
}

.card h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.card .desc {
  padding: 0.5rem 1rem;
}

.card .actions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 0.5rem 1rem;
}

.card button:hover {
  transform: rotate(5deg);
}

.card button {
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
  transition: 0.5s;
}
.far {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
